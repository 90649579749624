/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/globals.css";
import "@fontsource/mr-dafoe/400.css";
// Supports weights 100-700
import "@fontsource-variable/josefin-sans";
import "@fontsource/rubik";
import "@fontsource/roboto";
import "@fontsource/roboto/500.css";
import "@fontsource/rubik/600.css";
import "@fontsource/roboto/700.css";

import React from 'react';
import { PopupProvider } from './src/context/PopupContext';

export const wrapRootElement = ({ element }) => {
  return <PopupProvider>{element}</PopupProvider>;
};

export const onRouteUpdate = ({ location }) => {
  // Ensure ttq is available
  if (typeof window !== 'undefined' && window.ttq) {
    window.ttq.page();
  }
};